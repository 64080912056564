import { Base64 } from "js-base64";
//import { useCookies } from "vue3-cookies";
import { jwtDecode } from "jwt-decode";
//const { cookies } = useCookies();
//const runtimeConfig = useRuntimeConfig();

// const pineAuthName = runtimeConfig.public.NUXT_MY_APP_PINE_AUTH_NAME
//   ? runtimeConfig.public.NUXT_MY_APP_PINE_AUTH_NAME
//   : "pineauth";
const pineAuthName ="pineauth"

// const pineHubAuthsName = runtimeConfig.public.NUXT_MY_APP_PINE_HUB_AUTHS_NAME
//   ? runtimeConfig.public.NUXT_MY_APP_PINE_HUB_AUTHS_NAME
//   : "pineHubAuths";

const pineHubAuthsName = "pineHubAuths"

const isTokenExpired = function (hubName) {
  let token = getHubAuthToken(hubName);
  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken && decodedToken.exp < new Date().getTime() / 1000)
      return true;
  }
  return false;
};

const getAuthUser = function () {
  // return authorization header with jwt token
  const userAuthEncoded = useCookie(pineAuthName);
  if (!userAuthEncoded.value  || userAuthEncoded.value === null) {
    return null;
  }

  const userAuth = JSON.parse(Base64.decode(userAuthEncoded.value));
  return userAuth;
};

const getDomain = function () {
  const domain = window.location.hostname;
  const s = domain.split(".");
  if (s.length < 3) {
    return domain;
  }
  return s.slice(1).join(".");
};

const setAuthUser = function (userAuth) {
  const userAuthEncoded = Base64.encode(JSON.stringify(userAuth));
  useCookie(
    pineAuthName,
    userAuthEncoded,
    {maxAge:1210000,domain:window.location.hostname}
    //"14d",
   // null,
    //getDomain(window.location.hostname)
  );
};
const setHubAuths = function (hubAuths) {
  const hubAuthsEncoded = Base64.encode(JSON.stringify(hubAuths));
  useCookie(
    pineHubAuthsName,
    hubAuthsEncoded,
    {maxAge:1210000,domain:window.location.hostname}
    //"14d",
    //null,
    //getDomain(window.location.hostname)
  );
};

const resetAuthUser = function () {
  const au = getAuthUser();
  if (au === null) {
    return false;
  }
  useCookie(pineHubAuthsName,{domain:window.location.hostname});
  //cookies.remove(pineAuthName, null, getDomain(window.location.hostname));
};

const resetHubAuths = function () {
  // const au = getOakHubAuth();
  // if (au === null) {
  //     return false;
  // }
  useCookie(pineHubAuthsName,{domain:window.location.hostname});
  //cookies.remove(pineHubAuthsName, null, getDomain(window.location.hostname));
};

const getHubAuthHeader = function (hubName) {
  // return authorization header with jwt token for oak
  const oakHubAuthsEncoded = useCookie(pineHubAuthsName);
  if (!oakHubAuthsEncoded.value || oakHubAuthsEncoded.value === null) {
    return null;
  }

  const oakhubAuths = JSON.parse(Base64.decode(oakHubAuthsEncoded.value));

  let oakhubAuth = oakhubAuths.filter((auth) => {
    return auth.name === hubName;
  })[0];

  if (oakhubAuth) return { Authorization: "Bearer " + oakhubAuth.bearer };
  else return {};
};
const getHubAuthToken = function (hubName) {
  // return authorization header with jwt token for oak
  const oakHubAuthsEncoded = useCookie(pineHubAuthsName);
  if (!oakHubAuthsEncoded.value || oakHubAuthsEncoded.value === null) {
    return null;
  }
  const oakhubAuths = JSON.parse(Base64.decode(oakHubAuthsEncoded.value));
  let oakhubAuth = oakhubAuths.filter((auth) => {
    return auth.name === hubName;
  })[0];
  if (oakhubAuth) return oakhubAuth.bearer;
  else return null;
};

const getCookieByName = function (name) {
  return useCookie(name);
};

const setCookieByName = function (name, value) {
  useCookie(name, value, {maxAge:604800,domain:window.location.hostname});
  //cookies.set(name, value, "168h", null, getDomain(window.location.hostname));

};

const removeCookieByName = function (name) {
  useCookie(name,{domain:window.location.hostname});
  //cookies.remove(name, null, getDomain(window.location.hostname));
};
export {
  getAuthUser,
  setAuthUser,
  resetAuthUser,
  setHubAuths,
  getHubAuthHeader,
  resetHubAuths,
  getHubAuthToken,
  removeCookieByName,
  getCookieByName,
  setCookieByName,
  isTokenExpired,
};
