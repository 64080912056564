import { getHubAuthHeader } from "@/common/auth";
//const runtimeConfig = useRuntimeConfig();
import { useFetchWrapRequest } from "@/common/nav";
// const pineHubName = runtimeConfig.public.NUXT_MY_APP_PINE_HUB_NAME;
const pineHubName = "pinehub";
//  let apiUrl = import.meta.env.NUXT_MY_APP_OAK_API_URL
//let apiUrl = runtimeConfig.public.NUXT_MY_APP_OAK_API_URL;
let apiUrl = "https://api.pinehub.net/org";
let nodeSlsApiUrl = "https://ops.vesthub.net";
// .public.NUXT_MY_APP_ID
const doCreateAccount = useFetchWrapRequest(({ one }) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.pgrst.object+json",
    },
    body: JSON.stringify({
      app_id: one.app_id,
      first_name: one.first_name,
      last_name: one.last_name,
      email: one.email,
      phone_number: one.phone_number,
      request_type: one.request_type,
      state: one.state,
      metadata: {
        treelet_profile: one.metadata.your_profile,
        company_industry: one.metadata.company_industry,
        terms_and_conditions: one.metadata.terms_and_conditions,
        positions: one.metadata.positions,
      },
    }),
  };
  return useFetch(`${apiUrl}/registration_request`, requestOptions);
});

/////////////////////====================

const doGuestRequest = useFetchWrapRequest(({ one }) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.pgrst.object+json",
      Prefer: "return=representation",
    },
    body: JSON.stringify({
      request: { email: one.email, action_request: one.action_request },
      app_id: one.app_id,
    }),
  };
  return useFetch(`${apiUrl}/rpc/guest_request_submit`, requestOptions);
});

export const doFetchUserSummary = useFetchWrapRequest(({ userId }) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      ...getHubAuthHeader(pineHubName),
    },
    body: JSON.stringify({
      user_id: userId,
    }),
  };
  return useFetch(`${apiUrl}/rpc/user_get_summary`, requestOptions);
});

export const doFetchUploadSignatureAndPolicy = useFetchWrapRequest(
  ({ name, type, bucket, region }) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        // ...getHubAuthHeader(pineHubName),
      },
      body: JSON.stringify({
        name,
        type,
        bucket,
        region,
      }),
    };
    return useFetch(`${nodeSlsApiUrl}/s3-sign`, requestOptions);
  }
);

export const doS3Upload = (file, signatureAndPolicy) => {
  // const type = file.split(';')[0].split('/')[1]
  // const buffer = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ""),'base64');

  const requestOptions = {
    method: "PUT",
    headers: {},
    body: file,
  };
  return useFetch(signatureAndPolicy, requestOptions);
};

// const doLoginWithEmail = wrapRequest(({one}) => {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(
//             {
//                 email: one.email,
//                 pass: one.password,
//                 app_id: one.app_id
//             })
//     };
//     return fetch(`${apiUrl}/rpc/user_login_with_email`, requestOptions)
// });

//////////==================

// const doFetchDummyData = async ()=>{
//     const requestOptions= {
//         method:'GET',
//         headers:{
//             'Content-Type':'application/json'
//         },
//     };
//     const response = await fetch("https://jsonplaceholder.typicode.com/users", requestOptions);
//     console.log("SERVICE- RESPONSE-", response);
//     if(!response.ok){
//         throw new Error(`Request Failed ${response.status}`);
//     }
//     // const jsonData= await JSON.parse(response);
//     const jsonData = await response.json();
//     console.log("SERVICE- JsonData-",jsonData);
//     return jsonData;
// }

/////////==================

// const doLoginWithEmail = wrapRequest((appId, email, password) => {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(
//             {
//                 email: email,
//                 pass: password,
//                 app_id: appId
//             })
//     };
//     return fetch(`${apiUrl}/rpc/user_login_with_email`, requestOptions)
// });

// const doLoginWithAzureAd = wrapRequest((appId, email, credentials) => {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(
//             {
//                 email: email,
//                 credentials: credentials,
//                 app_id: appId
//             })
//     };
//     return fetch(`${apiUrl}/rpc/user_login_with_azure_ad`, requestOptions)
// });

// const doLogout = function () {
//     // remove user from local storage to log user out
//     localStorage.removeItem('user_token');
// }

// const doForgotPassword = wrapRequest((appId, email) => {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(
//             {
//                 app_id: appId,
//                 email: email
//             }
//         )
//     };
//     return fetch(`${apiUrl}/rpc/forgot_password`, requestOptions);
// });

// const doFetchUploadSignatureAndPolicy = wrapRequest(({ name, type, bucket, region }) => {

//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-type': 'application/json', ...getHubAuthHeader(oakHubName) },
//         body: JSON.stringify(
//             {
//                 name, type, bucket, region
//             }
//         )
//     };
//     return fetch(`${import.meta.env.VITE_APP_OAK_NODESLS_URL}/s3-sign`, requestOptions);
// });

// const doConfirmUserEmail = wrapRequest((userId, confirmToken) => {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(
//             {
//                 user_id: userId,
//                 token: confirmToken
//             }
//         )
//     };
//     return fetch(`${apiUrl}/rpc/confirm_user_email`, requestOptions);
// })

// const doResendConfirmationEmail = wrapRequest((appId, email) => {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(
//             {
//                 app_id: appId,
//                 email: email
//             }
//         )
//     };
//     return fetch(`${apiUrl}/rpc/user_require_email_confirmation`, requestOptions);
// })

// const doforgetPwdOtp = wrapRequest((email)=>{
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(
//             {
//                 email: email
//             }
//         )
//     };
//     return fetch(`${apiUrl}/rpc/user_require_email_confirmation`, requestOptions);
// })

export default {
  doGuestRequest,
  doCreateAccount,
  doFetchUserSummary,
  doFetchUploadSignatureAndPolicy,
  doS3Upload,
  // doLoginWithEmail
  // doChackAccount,
  // doLoginWithEmail,
  // doLoginWithAzureAd,
  // doLogout,
  // doForgotPassword,
  // doFetchUserState,
  // doFetchUploadSignatureAndPolicy,
  // doConfirmUserEmail,
  //doResendConfirmationEmail,
  // doforgetPwdOtp
  // doFetchResponse,
  // doFetchDummyData
};
