//import Vue from 'vue';
import { getAuthUser, setCookieByName } from "./auth";
//const runtimeConfig = useRuntimeConfig();

//import { getAuthUser, setCookieByName } from "./auth";
//const runtimeConfig = useRuntimeConfig();

// const pineAuthName = runtimeConfig.public.NUXT_MY_APP_PINE_AUTH_NAME
//   ? runtimeConfig.public.NUXT_MY_APP_PINE_AUTH_NAME
//   : "pineauth";
const pineAuthName = 'pineauth'
// const loginUrl = runtimeConfig.public.NUXT_MY_APP_LOGIN_URL
//   ? runtimeConfig.public.NUXT_MY_APP_LOGIN_URL
//   : "/auth/sign-in";

const loginUrl = 'https://app.treelet.net/gate/sign-in'
// const homeUrl = runtimeConfig.public.NUXT_MY_APP_HOME_URL
//   ? runtimeConfig.public.NUXT_MY_APP_HOME_URL
//   : "/";
const homeUrl ='/'

const nodeEnv = import.meta.env.NODE_ENV
  ? import.meta.env.NODE_ENV
  : "production";

// const trltInterruptedPage = runtimeConfig.public
//   .NUXT_MY_APP_INTERRUPTED_PAGE_NAME
//   ? runtimeConfig.public.NUXT_MY_APP_INTERRUPTED_PAGE_NAME
//   : "";
 
  const trltInterruptedPage ='tlet_interrupted'



const loginInterrupt = function (current_url, message) {
  setCookieByName(trltInterruptedPage, current_url);

  sessionStorage.interrupted_page = current_url;
  sessionStorage.interrupt_message = message;
  window.location.href = loginUrl;
};

const redirectToInterruptedPage = function () {
  // debugger
  const url = sessionStorage.interrupted_page;
  if (url && !url.includes(loginUrl)) window.location.href = url;
  else window.location.href = homeUrl;
};

const beforeEachRoute = function (to, from, next) {
  if (!to.matched.length) {
    location.href = to.path;
  }
  const requiresRole = to.meta ? to.meta.requiresRole : null;
  if (requiresRole) {
    const userAuth = getAuthUser();

    if (
      userAuth == null ||
      userAuth.grants == null ||
      !userAuth.grants.includes(requiresRole)
    ) {
      loginInterrupt(to.fullPath);
    }
  }

  next();
};

const useFetchWrapRequest =
  (fn) =>
  (...params) =>
    fn(...params)
      .then((response) => {
        if (response && response.error.value) {
          throw response.error.value;
        }
        let data = response.data.value;

        return data;
      })
      .catch((error) => handleUseFetchError(error));

const handleUseFetchError = async (errorResponse) => {
  let error = {};
  console.log("error Response", errorResponse);
  switch (errorResponse.statusCode) {
    case 401:
    case 400:
      // if (
      //   data.code == "22023" &&
      //   data.message.indexOf("role") >= 0 &&
      //   data.message.indexOf("does not exist")
      // ) {
      loginInterrupt(
        window.location.href,
        "For enhanced security reasons please sign back in."
      );
      //}
      break;
    default:
  } //switch
  error = {
    text: errorResponse.statusMessage,
    code: errorResponse.statusCode,
    message:
      nodeEnv === "production"
        ? "Cloud communication error"
        : errorResponse.message,
    //  url: errorResponse.url,  // for usefetch() the url is not received in error resp
  };
  console.log("Mode", nodeEnv);
  console.log("Error", error);
  throw error;

  // error = {
  //   text: errorResponse.statusText,
  //   code: errorResponse.status,
  //   message: "Cloud communication error",
  //   url: errorResponse.url,
  // };

  // throw error;
};
//this is for those who doesnt return a response
const wrapEmptyRequest =
  (fn) =>
  (...params) =>
    fn(...params)
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response;
      })
      .catch((error) => handleError(error));

const wrapRequest =
  (fn) =>
  (...params) =>
    fn(...params)
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .catch((error) => handleError(error));

const wrapRequestWithRange =
  (fn) =>
  (...params) =>
    fn(...params)
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        let data = response.json();
        return { data: data, range: response.headers.get("Content-Range") };
      })
      .catch((error) => handleError(error));

const handleError = async (errorResponse) => {
  if (!errorResponse.ok) {
    let data = null;
    let error = {};
    if (typeof errorResponse.text === "function") {
      let text = await errorResponse.text();
      try {
        data = JSON.parse(text);
      } catch (e) {
        data = { message: text };
      }
      switch (errorResponse.status) {
        case 403:
          // if (typeof Vue.$snotify != 'undefined') {
          //     Vue.$snotify.error(data.message);
          // }
          //throw data;
          break;
        case 401:
          loginInterrupt(
            window.location.href,
            "For enhanced security reasons please sign back in."
          );
          break;
        case 400:
          if (
            data.code == "22023" &&
            data.message.indexOf("role") >= 0 &&
            data.message.indexOf("does not exist")
          ) {
            loginInterrupt(
              window.location.href,
              "For enhanced security reasons please sign back in."
            );
          } else {
            // if (typeof Vue.$snotify != 'undefined') {
            //     Vue.$snotify.error('Unexpected error while communicating with the cloud');
            // }
            //throw data;
          }
          break;
        default:
        // if (typeof Vue.$snotify != 'undefined') {
        //     Vue.$snotify.error('Unexpected error while communicating with the cloud');
        // }

        /* error = {
                     text: errorResponse.statusText,
                     code: errorResponse.status,
                     message: nodeEnv ==='production'?"Cloud communication error":data.message,
                     url: errorResponse.url
                 };

                 throw error;*/
      } //switch
      error = {
        text: errorResponse.statusText,
        code: errorResponse.status,
        message:
          nodeEnv === "production" ? "Cloud communication error" : data.message,
        url: errorResponse.url,
      };
      console.log("Mode", nodeEnv);
      console.log("Error", error);
      throw error;
    } else {
      // if (typeof Vue.$snotify != 'undefined') {
      //     Vue.$snotify.error('Unexpected error while communicating with the cloud');
      // }

      error = {
        text: errorResponse.statusText,
        code: errorResponse.status,
        message: "Cloud communication error",
        url: errorResponse.url,
      };

      throw error;
    }
  }
};

export {
  loginInterrupt,
  redirectToInterruptedPage,
  beforeEachRoute,
  wrapRequest,
  handleError,
  wrapRequestWithRange,
  wrapEmptyRequest,
  useFetchWrapRequest,
};
